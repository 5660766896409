<template>
    <div class="login-page">
        <div class="login_imgbox">
            <div class="login_img">
            </div>
            <div class="login-container">

                <div class="login-title">全省法院执行专项工作管理平台</div>
                <div class="login-field">
                    <i class="el-icon-s-custom" style="color:#2e67fc;font-size:30px;margin-left: 20px"></i>
                    <input v-model="username" class="login-field-value" placeholder="请输入用户名" autocomplete="new-password"
                        type="text" />
                </div>
                <div class="login-field">
                    <i class="el-icon-lock" style="color:#2e67fc;font-size:30px;margin-left: 20px"></i>
                    <input v-model="password" class="login-field-value" placeholder="请输入密码" autocomplete="new-password"
                        type="password" />
                </div>
                <div class="login-checkbox" @click="save = !save">
                    <!-- <img
                            v-show="!save"
                            class="login-checkbox-icon"
                            src="~@/assets/image/5.png"
                            alt=""
                        /> -->
                    <div v-show="!save" style="width:28px;height:28px;border:solid 1px #666666;border-radius:100%">
                    </div>
                    <i v-show="save" class="el-icon-success" style="color:#2e67fc;font-size:28px;"></i>
                    <!-- <img
                            v-show="save"
                            class="login-checkbox-icon"
                            src="~@/assets/image/6.png"
                            alt=""
                        /> -->
                    <div class="login-checkbox-text">记住密码</div>
                </div>
                <div class="login-button" @click="onLogin">登录</div>
            </div>
        </div>

    </div>
</template>

<script>
import { post } from "@/assets/js/http.js";

export default {
    name: "view-login",
    data() {
        return {
            save: false,
            username: "",
            password: ""
        };
    },
    mounted() {
        this.save = localStorage.getItem("save") === "true";
        if (this.save) {
            this.username = localStorage.getItem("username");
            this.password = localStorage.getItem("password");
        } else {
            this.username = "";
            this.password = "";
        }
    },
    methods: {
        async onLogin() {
            if (this.username && this.password) {
                localStorage.setItem("save", this.save);
                if (this.save) {
                    localStorage.setItem("username", this.username);
                    localStorage.setItem("password", this.password);
                } else {
                    localStorage.removeItem("username");
                    localStorage.removeItem("password");
                }
                const data = await post({
                    url: "/admin/index/login",
                    loading: true,
                    successTip: true,
                    data: {
                        username: this.username,
                        password: this.password
                    }
                });
                localStorage.setItem("role", data.role);
                localStorage.setItem("adminId", data.userinfo.id);

                console.log(data);
                this.$store.commit("login", data.userinfo);
                this.$router.replace("/");
            } else {
                this.$message.error("用户名和密码不能为空");
            }
        }
    }
};
</script>