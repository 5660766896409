import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Index from "../views/index.vue";
import Login from "../views/login.vue";
import { Message } from "element-ui";

Vue.use(VueRouter);
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "view-index",
    component: Index,
    children: [
      {
        path: "/",
        name: "view-dashboard",
        component: () =>
          import(
            /* webpackChunkName: 'view-dashboard' */ "../views/dashboard/index.vue"
          ),
      },
      {
        path: "/menu",
        name: "view-menu",
        component: () =>
          import(/* webpackChunkName: 'view-menu' */ "../views/menu/index.vue"),
      },
      {
        path: "/admin",
        name: "view-admin",
        component: () =>
          import(
            /* webpackChunkName: 'view-admin' */ "../views/admin/index.vue"
          ),
      },
      {
        path: "/role",
        name: "view-role",
        component: () =>
          import(/* webpackChunkName: 'view-role' */ "../views/role/index.vue"),
      },
      {
        path: "/member",
        name: "view-member",
        component: () =>
          import(
            /* webpackChunkName: 'view-member' */ "../views/member/index.vue"
          ),
      },
      {
        path: "/Listingsupervision",
        name: "view-Listingsupervision",
        component: () =>
          import(
            /* webpackChunkName: 'view-Listingsupervision' */ "../views/Listingsupervision/index.vue"
          ),
      },
      {
        path: "/cancellation",
        name: "view-cancellation",
        component: () =>
          import(
            /* webpackChunkName: 'view-cancellation' */ "../views/cancellation/index.vue"
          ),
      },
      {
        path: "/toexamine",
        name: "view-toexamine",
        component: () =>
          import(
            /* webpackChunkName: 'view-toexamine' */ "../views/toexamine/index.vue"
          ),
      },

      {
        path: "/returndo",
        name: "view-returndo",
        component: () =>
          import(
            /* webpackChunkName: 'view-returndo' */ "../views/returndo/index.vue"
          ),
      },
      {
        path: "/filling",
        name: "view-filling",
        component: () =>
          import(
            /* webpackChunkName: 'view-filling' */ "../views/filling/index.vue"
          ),
      },
      {
        path: "/refill",
        name: "view-refill",
        component: () =>
          import(
            /* webpackChunkName: 'view-refill' */ "../views/refill/index.vue"
          ),
      },
      {
        path: "/member-detail",
        name: "view-member-detail",
        component: () =>
          import(
            /* webpackChunkName: 'view-member-detail' */ "../views/memberinfo/infos.vue"
          ),
      },
      {
        path: "/admin-log",
        name: "view-admin-log",
        component: () =>
          import(
            /* webpackChunkName: 'view-admin-log' */ "../views/admin-log/index.vue"
          ),
      },
      {
        path: "/open-door-records",
        name: "view-open-door-records",
        component: () =>
          import(
            /* webpackChunkName: 'view-open-door-records' */ "../views/open-door-records/index.vue"
          ),
      },
      {
        path: "/shop",
        name: "view-shop",
        component: () =>
          import(/* webpackChunkName: 'view-shop' */ "../views/shop/index.vue"),
      },
      {
        path: "/article-type",
        name: "view-article-type",
        component: () =>
          import(
            /* webpackChunkName: 'view-article-type' */ "../views/article-type/index.vue"
          ),
      },
      {
        path: "/article",
        name: "view-article",
        component: () =>
          import(
            /* webpackChunkName: 'view-article' */ "../views/article/index.vue"
          ),
      },
      {
        path: "/system-config",
        name: "view-system-config",
        component: () =>
          import(
            /* webpackChunkName: 'view-system-config' */ "../views/system-config/index.vue"
          ),
      },
      {
        path: "/file",
        name: "view-file",
        component: () =>
          import(/* webpackChunkName: 'view-file' */ "../views/file/index.vue"),
      },
      {
        path: "/archive",
        name: "view-archive",
        component: () =>
          import(
            /* webpackChunkName: 'view-archive' */ "../views/archive/index.vue"
          ),
      },
      {
        path: "/material",
        name: "view-material",
        component: () =>
          import(
            /* webpackChunkName: 'view-material' */ "../views/material/index.vue"
          ),
      },
      {
        path: "/reservation",
        name: "view-reservation",
        component: () =>
          import(
            /* webpackChunkName: 'view-reservation' */ "../views/reservation/index.vue"
          ),
      },
      {
        path: "/rfm",
        name: "view-rfm",
        component: () =>
          import(/* webpackChunkName: 'view-rfm' */ "../views/rfm/index.vue"),
      },
      {
        path: "/rfm-setup",
        name: "view-rfm-setup",
        component: () =>
          import(
            /* webpackChunkName: 'view-rfm-setup' */ "../views/rfm-setup/index.vue"
          ),
      },
      {
        path: "/rfm-detail",
        name: "view-rfm-detail",
        component: () =>
          import(
            /* webpackChunkName: 'view-rfm-detail' */ "../views/rfm-detail/index.vue"
          ),
      },
      {
        path: "/issue-coupons",
        name: "view-issue-coupons",
        component: () =>
          import(
            /* webpackChunkName: 'view-issue-coupons' */ "../views/issue-coupons/index.vue"
          ),
      },
      {
        path: "/coupon",
        name: "view-coupon",
        component: () =>
          import(
            /* webpackChunkName: 'view-coupon' */ "../views/coupon/index.vue"
          ),
      },
      {
        path: "/goods",
        name: "view-goods",
        component: () =>
          import(
            /* webpackChunkName: 'view-goods' */ "../views/goods/index.vue"
          ),
      },
      {
        path: "/bi",
        name: "view-bi",
        component: () =>
          import(/* webpackChunkName: 'view-bi' */ "../views/bi/index.vue"),
      },
      {
        path: "/goods/add",
        name: "view-goods-add",
        component: () =>
          import(
            /* webpackChunkName: 'view-goods-add' */ "../views/goods/add.vue"
          ),
      },
      {
        path: "/goods-type",
        name: "view-goods-type",
        component: () =>
          import(
            /* webpackChunkName: 'view-goods-type' */ "../views/goods-type/index.vue"
          ),
      },
      {
        // 打击执据列表
        path: "/strikeList",
        name: "view-StrikeList",
        component: () =>
          import(
            /* webpackChunkName: 'view-strikeList' */ "../views/strikeList/index.vue"
          ),
      },
      {
        // 审核列表
        path: "/strikeAudit",
        name: "view-strikeAudit",
        component: () =>
          import(
            /* webpackChunkName: 'view-strikeAudit' */ "../views/strikeAudit/index.vue"
          ),
      },
      {
        // 填报信息
        path: "/caseInfo",
        name: "view-caseInfo",
        component: () =>
          import(
            /* webpackChunkName: 'view-caseInfo' */ "../views/caseInfo/index.vue"
          ),
      },
      {
        // 打击拒执接收任务列表
        path: "/InitiateTask",
        name: "view-InitiateTask",
        component: () =>
          import(
            /* webpackChunkName: 'view-InitiateTask' */ "../views/InitiateTask/index.vue"
          ),
      },
	  {
	          // 打击发送的拒执任务列表
	          path: "/djjzsend",
	          name: "view-djjzsend",
	          component: () =>
	            import(
	              /* webpackChunkName: 'view-InitiateTask' */ "../views/InitiateTask/djjzsend.vue"
	            ),
	        },

      {
        // 执转破总表
        path: "/zzp",
        name: "view-zzp",
        component: () =>
          import(/* webpackChunkName: 'view-zzp' */ "../views/zzp/index.vue"),
      },
      {
        // 执转破审核列表
        path: "/highexamine",
        name: "view-highexamine",
        component: () =>
          import(
            /* webpackChunkName: 'view-highexamine' */ "../views/highexamine/index.vue"
          ),
      },

      {
        // 执转破任务列表，中院收到的列表（高院发的）
        path: "/zzpFaBu",
        name: "view-zzpFaBu",
        component: () =>
          import(
            /* webpackChunkName: 'view-zzpFaBu' */ "../views/zzpFaBu/index.vue"
          ),
      },
	  {
	    // 执转破任务列表，中院发给初院的列表
	    path: "/receive",
	    name: "view-receive",
	    component: () =>
	      import(
	        /* webpackChunkName: 'view-zzpFaBu' */ "../views/zzpFaBu/receive.vue"
	      ),
	  },
      {
        // 执转破填报信息
        path: "/zzpInfo",
        name: "view-zzpInfo",
        component: () =>
          import(
            /* webpackChunkName: 'view-zzpInfo' */ "../views/zzpInfo/index.vue"
          ),
      },
      // 金融债权
      {
        // 金融债权统计表
        path: "/financialClaimStatistics",
        name: "view-financialClaimStatistics",
        component: () =>
          import(
            /* webpackChunkName: 'view-financialClaimStatistics' */ "../views/financeList/financialClaimStatistics/index.vue"
          ),
      },
      {
        // 申请督办
        path: "/applyForHandle",
        name: "view-applyForHandle",
        component: () =>
          import(
            /* webpackChunkName: 'view-applyForHandle' */ "../views/financeList/applyForHandle/index.vue"
          ),
      },
      {
        // 甄别报送
        path: "/screening",
        name: "view-screening",
        component: () =>
          import(
            /* webpackChunkName: 'view-screening' */ "../views/financeList/screening/index.vue"
          ),
      },
      {
        // 挂牌督办/
        path: "/gpSupervise",
        name: "view-gpSupervise",
        component: () =>
          import(
            /* webpackChunkName: 'view-gpSupervise' */ "../views/financeList/gpSupervise/index.vue"
          ),
      },
      {
        // 申请销号
        path: "/applyDestroy",
        name: "view-applyDestroy",
        component: () =>
          import(
            /* webpackChunkName: 'view-applyDestroy' */ "../views/financeList/applyDestroy/index.vue"
          ),
      },
      {
        // 申请销号
        path: "/check",
        name: "view-check",
        component: () =>
          import(
            /* webpackChunkName: 'view-check' */ "../views/financeList/check/index.vue"
          ),
      },
      {
        // 退回重办/feedback
        path: "/returnHandle",
        name: "view-returnHandle",
        component: () =>
          import(
            /* webpackChunkName: 'view-returnHandle' */ "../views/financeList/returnHandle/index.vue"
          ),
      },
      {
        // 督办反馈
        path: "/feedback",
        name: "view-feedback",
        component: () =>
          import(
            /* webpackChunkName: 'view-feedback' */ "../views/financeList/feedback/index.vue"
          ),
      },
      {
        // 修改密码
        path: "/editpwd",
        name: "edit-password",
        component: () =>
          import(
            /* webpackChunkName: 'view-feedback' */ "../views/editpwd/index.vue"
          ),
      },
	  {
	    // 强制措施
	    path: "/coercive",
	    name: "view-coercive",
	    component: () =>
	      import(
	        /* webpackChunkName: 'view-feedback' */ "../views/coercive/index.vue"
	      ),
	  },
    ],
  },
  {
    path: "/login",
    name: "view-login",
    component: Login,
  },
  {
    path: "*",
    name: "view-404",
    component: () =>
      import(/* webpackChunkName: 'view-404' */ "../views/404.vue"),
  },
];

const router = new VueRouter({
  routes,
  //判断路由跳转之后是否是发生滚动 如果发生了滚动返回到最上面
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.path === "/rfm-detail") {
    if (
      store.state.buttons.findIndex((item) => item.path === "rfm-detail") >= 0
    ) {
      next();
    } else {
      Message.error("您没有该页面访问权限");
    }
  } else if (to.path === "/rfm-setup") {
    if (
      store.state.buttons.findIndex((item) => item.path === "rfm-setup") >= 0
    ) {
      next();
    } else {
      Message.error("您没有该页面访问权限");
    }
  } else {
    if (to.path !== "/login" && !store.state.token) {
      router.replace("/login");
    } else {
      next();
    }
  }
});

export default router;
