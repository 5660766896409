import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls"
const ls = new SecureLS({ isCompression: false })

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: "",
        avatar: "",
        username: "",
        nickname: '',
        buttons: [],
        menu: []
    },
    mutations: {
        login(state, payload) {
            state.token = payload.token;
        },
        logout(state) {
            state.token = "";
        },
        setAdminInfo(state, payload) {
            state.avatar = payload.avatar;
            state.username = payload.username;
            state.nickname = payload.nickname;
        },
        setButtons(state, payload) {
            state.buttons = payload;
        },
        setMenu(state, menus) {
            state.menu = menus
        }
    },
    actions: {
        setMenu(ctx, menus) {
            ctx.commit('setMenu', menus)
        }
    },
    modules: {
    },
    plugins: [
        createPersistedState({
            key: 'token',
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ]
})
