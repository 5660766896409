import Vue from 'vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import 'font-awesome/css/font-awesome.min.css'
import globalComponents from "./assets/js/globalComponents.js"
import globalFilters from "./assets/js/globalFilters.js"
import { auth } from '@/utils/auth'
Vue.use(globalComponents)
Vue.use(globalFilters)
Vue.mixin({
	computed: {
		buttons() {
			return this.$store.state.buttons;
		}
	},
	methods: {
		findButton(name) {
			return this.buttons.find(item => item.title === name)
		},
	}
})
Vue.config.productionTip = false
Vue.prototype.$auth = auth
import App from './App.vue'

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')